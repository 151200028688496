"use client";
import { ThemeContext } from "#/providers/theme-provider";
import { ComposedSelect } from "#/ui/select";
import { useRouter } from "next/navigation";
import { useContext, useEffect, useMemo, useState } from "react";

export const SwitchRegionSelect = () => {
  const theme = useContext(ThemeContext);
  const [region, setRegion] = useState("us");
  const router = useRouter();

  useEffect(() => {
    const hostname = window.location.hostname;
    if (hostname.startsWith("eu.")) {
      setRegion("eu");
    } else {
      setRegion("us");
    }
  }, []);

  const options = useMemo(
    () => [
      { label: "EU 🇪🇺", value: "eu" },
      { label: "US 🇺🇸", value: "us" },
    ],
    [],
  );

  const domain = useMemo(() => {
    const protocol = window.location.protocol;
    const host = window.location.host;

    const envToDomainMap = {
      "streamyourpos.com": "eu.streamorders.com",
      "eu.streamorders.com": "streamyourpos.com",
      "dev-unstable-app.hookedapi.com": "eu.dev-unstable-app.hookedapi.com",
      "eu.dev-unstable-app.hookedapi.com": "dev-unstable-app.hookedapi.com",
      "eu.localhost:3000": "localhost:3000",
      "localhost:3000": "eu.localhost:3000",
    };

    const domain = envToDomainMap[host] || host;
    return `${protocol}//${domain}`;
  }, []);

  const partnerWithEU = ["Stream"].includes(theme.name);

  if (!partnerWithEU) return <></>;

  return (
    <ComposedSelect
      value={region}
      className="w-[80px] h-[35px] pl-2 bg-card"
      onChange={(value) => {
        router.replace(domain);
      }}
      data={options}
    />
  );
};
