import { atoms } from "#/lib/atoms/atoms";
import { queries } from "#/lib/atoms/queries";
import { useAtom, useAtomValue } from "jotai";
import { useMemo } from "react";

export function useCurrentLocation() {
  const [{ data }] = useAtom(queries.locations);
  const currentLocationId = useAtomValue(atoms.currentLocationId);

  return useMemo(() => {
    return data?.locations?.find(
      (location) => location._id === currentLocationId,
    );
  }, [data?.locations, currentLocationId]);
}
