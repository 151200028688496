"use client";

import { Label } from "#/ui/label";
import { Period } from "#/utils/time-picker-utils";
import * as React from "react";
import { TimePeriodSelect } from "./period-select";
import { TimePickerInput } from "./time-picker-input";

interface TimePickerDemoProps {
  date: Date | undefined;
  setDate: (date: Date | undefined) => void;
  showLabels?: boolean;
  disabled?: boolean;
}

export function TimePickerPeriodInput({
  date,
  disabled,
  showLabels,
  setDate,
}: TimePickerDemoProps) {
  const getInitialPeriod = (date: Date | undefined): Period => {
    if (!date) return "AM";
    const hours = date.getHours();
    return hours >= 12 ? "PM" : "AM";
  };

  const period = React.useMemo(() => getInitialPeriod(date), [date]);

  const updateHours = (hours: number) => {
    if (!date) return;
    const newDate = new Date(date);
    const currentPeriod = period;
    if (hours >= 12 && currentPeriod === "AM") {
      newDate.setHours(hours);
    } else if (hours < 12 && currentPeriod === "PM") {
      newDate.setHours(hours + 12);
    } else {
      newDate.setHours(hours);
    }
    setDate(newDate);
  };

  const updateMinutes = (minutes: number) => {
    if (!date) return;
    const newDate = new Date(date);
    newDate.setMinutes(minutes);
    setDate(newDate);
  };

  const minuteRef = React.useRef<HTMLInputElement>(null);
  const hourRef = React.useRef<HTMLInputElement>(null);
  const secondRef = React.useRef<HTMLInputElement>(null);
  const periodRef = React.useRef<HTMLButtonElement>(null);

  return (
    <div className="flex">
      <div className="flex items-end">
        <div className="grid gap-1 text-center">
          {showLabels && (
            <Label htmlFor="hours" className="text-xs">
              Hours
            </Label>
          )}

          <TimePickerInput
            picker="12hours"
            period={period}
            date={date}
            disabled={disabled}
            ref={hourRef}
            onRightFocus={() => minuteRef.current?.focus()}
            className="rounded-l-xl rounded-r-none border-0"
            setDate={(newDate) => updateHours(newDate.getHours())}
          />
        </div>
        <div className="grid gap-1 text-center">
          {showLabels && (
            <Label htmlFor="minutes" className="text-xs">
              Minutes
            </Label>
          )}
          <TimePickerInput
            picker="minutes"
            id="minutes12"
            date={date}
            ref={minuteRef}
            disabled={disabled}
            onLeftFocus={() => hourRef.current?.focus()}
            onRightFocus={() => secondRef.current?.focus()}
            className="rounded-none border-t-0 border-b-0 border-r-0"
            setDate={(newDate) => updateMinutes(newDate.getMinutes())}
          />
        </div>

        <div className="grid gap-1 text-center">
          {showLabels && (
            <Label htmlFor="period" className="text-xs">
              Period
            </Label>
          )}
          <TimePeriodSelect
            period={period}
            ref={periodRef}
            disabled={disabled}
            setPeriod={(newPeriod) => {
              if (!date) return;
              const newDate = new Date(date);
              const currentHours = newDate.getHours();
              if (newPeriod === "AM" && currentHours >= 12) {
                newDate.setHours(currentHours - 12);
              } else if (newPeriod === "PM" && currentHours < 12) {
                newDate.setHours(currentHours + 12);
              }
              setDate(newDate);
            }}
            date={date}
            setDate={setDate}
            className="rounded-none border-0 bg-background rounded-r-xl border-l-[1px]"
          />
        </div>
      </div>
    </div>
  );
}
