import { useCurrentLocation } from "#/hooks/use-current-location";
import { queries } from "#/lib/atoms/queries";
import { useAtom } from "jotai";

export default function useIntegrations() {
  const currentLocation = useCurrentLocation();
  const [{ data, isLoading }] = useAtom(queries.integrations);

  return {
    loading: isLoading,
    data: data || {
      pos: [],
      dsp: [],
    },
    pos: data?.pos?.find((p) => currentLocation?.integration_id === p._id),
  };
}
