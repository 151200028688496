import { queryClient } from "#/lib/query";
import { useAtom, useSetAtom } from "jotai";
import { useEffect, useState } from "react";

import { atoms } from "#/lib/atoms/atoms";
import { queries } from "#/lib/atoms/queries";

export default function useLocations() {
  const [selectedLocations, setSelectedLocations] = useState([]);
  const setCurrentLocationId = useSetAtom(atoms.currentLocationId);

  const [{ data }] = useAtom(queries.locations);
  useEffect(() => {
    if (data?.locations?.length) {
      const found = data?.locations?.find(
        (i) => i?._id === JSON.parse(localStorage.getItem("currentLocationId")),
      );

      if (found) {
        setCurrentLocationId(found._id);
      } else {
        setCurrentLocationId(data?.locations[0]._id);
      }
    }
  }, [data?.locations, setCurrentLocationId]);

  const handleSelectedLocation = (selectedLocation) => {
    setSelectedLocations((prevState) => {
      if (prevState.includes(selectedLocation._id)) {
        return [...prevState.filter((item) => item !== selectedLocation._id)];
      }
      return [...prevState, selectedLocation._id];
    });
  };

  return {
    data: data?.locations || [],
    handleSelectedLocation,
    // this is needed because without it we prompt for pos onboarding before actual load
    // https://github.com/hookedapp/stream-dashboard-web/blob/930f36e6e75375c61004eeb6a3506f50a879602e/lib/atoms/queries.ts#L40
    loading:
      data === null ||
      queryClient.isFetching({
        queryKey: ["locations"],
      }),
    selectedLocations,
    setLocation: setCurrentLocationId,
  };
}
