export const SUBSCRIPTION_STATUSES = {
  active: "active",
  bad_standing: "bad_standing",
  does_not_exist: "does_not_exist",
  payment_error: "payment_error",
  trialing: "trialing",
  past_due: "past_due",
};

export const SUBSCRIPTION_NAMES = {
  per_month: "Standard plan",
  per_month_location: "Standard plan",
  per_month_location_premium: "Premium plan",
};

export enum SUBSCRIPTION_TIERS {
  per_month = "per_month",
  per_month_location = "per_month_location",
  per_month_location_premium = "per_month_location_premium",
}

export const SUBSCRIPTION_STATUS_CTA_TITLE = {
  active: "Stripe",
  trialing: "Stripe",
  bad_standing: "Payment issue",
  cancelled: "Cancelled",
  payment_error: "Payment issue",
};

export const SUBSCRIPTION_STATUS_CTA_BUTTON = {
  active: "Edit",
  bad_standing: "Update",
  cancelled: "Renew",
  payment_error: "Update",
};

export const SUBSCRIPTION_STATUS_CTA_DESCRIPTIONS = {
  active: "Payment",
  trialing: "Payment",
  bad_standing: "Update payment",
  cancelled: "Renew",
  payment_error: "Update payment",
};

// this means a subscription can be managed
export function isActiveSubscription(subscription) {
  return (
    subscription?.status === SUBSCRIPTION_STATUSES.active ||
    subscription?.status === SUBSCRIPTION_STATUSES.trialing ||
    subscription?.status === SUBSCRIPTION_STATUSES.past_due
  );
}
