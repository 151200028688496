"use client";

import { HighlightImage } from "#/components/highlight-image";
import { useTStream } from "#/hooks/use-t-stream";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "#/ui/alert-dialog";
import { CheckCircle2Icon } from "lucide-react";
import { useCallback } from "react";

type Props = {
  title?: string;
  subtitle?: string;
  onConfirm(): void;
  description: string;
  trigger: React.ReactNode;
};

export const ManualConfirmDialog = ({
  showDialog,
  setShowDialog,
  onConfirm,
  onCancel,
}) => {
  const { t } = useTStream("common");
  return (
    <AlertDialog open={showDialog} onOpenChange={setShowDialog}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{t("Unsaved Changes")}</AlertDialogTitle>
          <AlertDialogDescription>
            {t(
              `You have unsaved changes. Are you sure you want to leave? Your changes will be lost.`,
            )}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel data-testid="stay-button" onClick={onCancel}>
            {t("Stay")}
          </AlertDialogCancel>
          <AlertDialogAction onClick={onConfirm}>
            {t("Leave")}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export const ConfirmDialog = ({ trigger, description, onConfirm }: Props) => {
  const { t } = useTStream("common");

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>{trigger}</AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{t(`Are you sure?`)}</AlertDialogTitle>
          {description && (
            <AlertDialogDescription className="text-muted">
              {description}
            </AlertDialogDescription>
          )}
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>{t("Cancel")}</AlertDialogCancel>
          <AlertDialogAction onClick={onConfirm}>
            {t("Continue")}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export const ConfirmDialogStacked = ({
  trigger,
  description,
  onConfirm,
  title,
}: {
  trigger: React.ReactNode;
  title: string;
  description: string;
  onConfirm: (e?: any) => void | Promise<void>;
}) => {
  const { t } = useTStream("common");

  const stopPropagation = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => e.stopPropagation(),
    [],
  );

  return (
    <AlertDialog>
      <AlertDialogTrigger onClick={stopPropagation} asChild>
        {trigger}
      </AlertDialogTrigger>
      <AlertDialogContent className="max-w-[400px]">
        <AlertDialogHeader className="text-center items-center">
          <HighlightImage
            rootStyles="h-14 w-14"
            bgStyles="bg-primary"
            image={<CheckCircle2Icon className="text-primary h-6 w-6" />}
          />
          <AlertDialogTitle className="text-center">{title}</AlertDialogTitle>
          {description && (
            <AlertDialogDescription className="text-muted">
              {description}
            </AlertDialogDescription>
          )}
        </AlertDialogHeader>
        <AlertDialogFooter className="flex justify-between">
          <AlertDialogCancel onClick={stopPropagation} className="w-full h-10">
            {t("Cancel")}
          </AlertDialogCancel>
          <AlertDialogAction className="w-full h-10" onClick={onConfirm}>
            {t("Continue")}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
