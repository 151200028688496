export const Images = {
  smartMenu: "https://cdn.hookedapi.com/assets/smart-menu.png",
  onboarding_pos: "https://cdn.hookedapi.com/assets/pos-onboard.png",
  orderDemo: "https://cdn.hookedapi.com/assets/orders-paywall.png",
  toast_demo: "https://cdn.streamorders.com/toast_demo.png",
  failed: "https://cdn.hookedapi.com/assets/failed.png",
  checkmark: "https://cdn.hookedapi.com/assets/check.png",
  logoDark: "https://cdn.hookedapi.com/assets/logo.png",
  logo: "https://cdn.hookedapi.com/assets/logo-light.png",
  icon: "https://cdn.hookedapi.com/assets/icon.png",
};
