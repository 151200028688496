const ZERO_DECIMAL_CURRENCIES = [
  "BIF",
  "CLP",
  "DJF",
  "GNF",
  "JPY",
  "KMF",
  "KRW",
  "MGA",
  "PYG",
  "RWF",
  "UGX",
  "VND",
  "VUV",
  "XAF",
  "XOF",
  "XPF",
];

export const FormatCents = (price: number, currency: string) => {
  if (!currency) return "error";
  const adjustedPrice = ZERO_DECIMAL_CURRENCIES.includes(currency)
    ? price
    : price / 100;
  return new Intl.NumberFormat(navigator.language, {
    style: "currency",
    currency,
  }).format(adjustedPrice);
};

export const getCurrencySymbol = (
  currencyCode = "USD",
  locale = navigator.language,
) => {
  const symbolFormatter = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currencyCode,
  });

  const parts = symbolFormatter.formatToParts(1);
  const currencySymbol = parts.find((part) => part.type === "currency").value;

  return currencySymbol;
};
