"use client";

import { format } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";

import { useTStream } from "#/hooks/use-t-stream";
import { cn } from "#/lib/utils";
import { Button } from "#/ui/button";
import { Calendar } from "#/ui/calendar";
import { Form, FormControl, FormField, FormItem, FormLabel } from "#/ui/form";
import { Popover, PopoverContent, PopoverTrigger } from "#/ui/popover";
import { TimePickerPeriodInput } from "#/ui/time-picker-period-input";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

const formSchema = z.object({
  dateTime: z.date(),
});

type FormSchemaType = z.infer<typeof formSchema>;

export function DateTimePickerForm({
  disabled = false,
  onUpdate,
  className = "",
  date,
}) {
  const { t } = useTStream("common");
  const form = useForm<FormSchemaType>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      dateTime: date || new Date(),
    },
  });

  useEffect(() => {
    const subscription = form.watch((value) => {
      if (value.dateTime && !isNaN(value.dateTime.getTime())) {
        onUpdate(value.dateTime);
      }
    });
    return () => subscription.unsubscribe();
  }, [form.watch, onUpdate]);

  // Only update form if external date changes significantly
  useEffect(() => {
    if (
      date &&
      Math.abs(form.getValues("dateTime").getTime() - date.getTime()) > 1000
    ) {
      form.setValue("dateTime", date);
    }
  }, [date]);

  return (
    <Form {...form}>
      <form className="flex items-center gap-4 justify-center">
        <FormField
          control={form.control}
          name="dateTime"
          render={({ field }) => (
            <FormItem className="flex flex-col relative">
              <FormLabel className="text-left z-10 absolute left-4 top-[1px] bg-card text-muted">
                {t("End date & time")}
              </FormLabel>
              <Popover>
                <FormControl>
                  <PopoverTrigger asChild>
                    <Button
                      variant="outline-muted"
                      disabled={disabled}
                      className={cn(
                        "justify-start text-left font-normal",
                        !field.value && "text-muted-foreground",
                        className,
                      )}
                    >
                      <CalendarIcon className="mr-2 text-muted h-4 w-4" />
                      {field.value ? (
                        format(field.value, "PPP HH:mm:ss")
                      ) : (
                        <span className="text-muted">Pick a date</span>
                      )}
                    </Button>
                  </PopoverTrigger>
                </FormControl>
                <PopoverContent className="w-auto p-0">
                  <Calendar
                    mode="single"
                    selected={field.value}
                    onSelect={field.onChange}
                    initialFocus
                  />
                  <div className="p-3 border-t border-border">
                    <TimePickerPeriodInput
                      setDate={field.onChange}
                      date={field.value}
                    />
                  </div>
                </PopoverContent>
              </Popover>
            </FormItem>
          )}
        />
      </form>
    </Form>
  );
}
