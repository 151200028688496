import { atoms } from "#/lib/atoms/atoms";
import { resyncMutation } from "#/lib/atoms/mutations";
import cookies from "#/lib/cookies";
import { queryClient } from "#/lib/query";
import { fetchRequest, postRequest } from "#/lib/stream-api";
import { IFixMe } from "#/types.ts/other";
import { components } from "#/types.ts/swagger";
import { QueryObserverOptions } from "@tanstack/react-query";
import { Getter } from "jotai";
import { atomWithSuspenseQuery } from "jotai-tanstack-query";

const wrapper = <
  TQueryData = unknown,
  TQueryFnData = unknown,
  TError = unknown,
>(
  getOptions: (
    get: Getter,
  ) => QueryObserverOptions<TQueryFnData, TError, TQueryData>,
) => {
  return atomWithSuspenseQuery(
    (p) => getOptions(p),
    () => queryClient,
  );
};

export const queries = {
  posIntegrationsList: wrapper<components["schemas"]["PublicPosConfig"][]>(
    (get) => ({
      queryKey: ["pos-integrations-list", get(atoms.orgId)],
      queryFn: async () => {
        return fetchRequest(`/integrations/list/pos`);
      },
    }),
  ),

  dspIntegrationsList: wrapper<components["schemas"]["PublicDspConfig"][]>(
    (get) => ({
      queryKey: ["dsp-integrations-list", get(atoms.orgId)],
      queryFn: async () => {
        return fetchRequest(`/integrations/list/delivery`);
      },
    }),
  ),
  user: wrapper<components["schemas"]["GetUserDto"]>((get) => ({
    queryKey: ["user", get(atoms.accessToken)],
    queryFn: async ({ queryKey: [, token] }) => {
      if (!token) return null;
      try {
        const data = await fetchRequest(`/user`);
        return data;
      } catch (error) {
        if (error.message === "Email not verified") {
          return { _id: null, email_verified: false };
        }

        throw error;
      }
    },
  })),
  dspLocations: wrapper<components["schemas"]["GetDspLocationsResponseDto"]>(
    (get) => ({
      queryKey: ["dsp-locations", get(atoms.mid)],
      queryFn: () => {
        return fetchRequest(`/dsp/locations`);
      },
    }),
  ),
  customRoles: wrapper<components["schemas"]["CustomRole"][]>((get) => ({
    queryKey: ["custom-roles", get(atoms.orgId)],
    queryFn: () => {
      return fetchRequest(`/custom-role/org-roles`);
    },
  })),
  locations: wrapper<components["schemas"]["GetMerchantLocationsResponseDto"]>(
    (get) => ({
      queryKey: ["locations", get(atoms.mid), get(atoms.accessToken)],
      queryFn: ({ queryKey: [, mid, token] }) => {
        if (mid === null || token === null) return null;
        return fetchRequest(`/location/merchant`, {
          headers: {
            merchant_id: mid as string,
          },
        });
      },
    }),
  ),
  locationEventProviders: wrapper<
    components["schemas"]["GetLocationEventProvidersResponseDto"]
  >(() => ({
    queryKey: ["locationEventProviders"],
    queryFn: ({ queryKey: [, mid, token] }) => {
      if (mid === null || token === null) return null;
      return fetchRequest(`/location-event/providers`, {
        headers: {
          merchant_id: mid as string,
        },
      });
    },
  })),

  merchant: wrapper<components["schemas"]["Merchant"]>((get) => ({
    queryKey: ["merchant", get(atoms.mid), get(atoms.accessToken)],
    queryFn: ({ queryKey: [, mid] }) => {
      if (mid === null) return null;
      return fetchRequest(`/merchant?id=${mid}`);
    },
  })),
  orgs: wrapper<components["schemas"]["OrgWithMerchant"][]>((get) => ({
    queryKey: ["orgs", get(atoms.accessToken)],
    queryFn: async ({ queryKey: [, token] }) => {
      if (!token) return [];
      const response = await fetchRequest("/org");
      return response?.sort((a, b) => a?.name?.localeCompare(b?.name));
    },
  })),
  integrations: wrapper<components["schemas"]["ListIntegrationsResponseDto"]>(
    (get) => ({
      queryKey: ["integrations", get(atoms.accessToken), get(atoms.mid)],
      queryFn: async ({ queryKey: [, token, mid] }) => {
        if (!token || !mid) return null;
        return fetchRequest(`/integrations`, {
          headers: {
            merchant_id: mid as string,
          },
        });
      },
    }),
  ),
  catalogDetails: wrapper<
    components["schemas"]["GetLocationCatalogDetailsResponseDto"]
  >((get) => ({
    refetchOnWindowFocus: true,
    queryKey: ["catalog", "details", get(atoms.currentLocationId)],
    queryFn: async ({ queryKey: [] }) => {
      const locationId = get(atoms.currentLocationId);
      if (!locationId) return { last_updated_at: "" };
      try {
        return await fetchRequest(`/catalog/details?location_id=${locationId}`);
      } catch (error) {
        console.log(error.message);
        if (error.message === "no catalog for location") {
          const locations: components["schemas"]["GetMerchantLocationsResponseDto"] =
            queryClient.getQueryData([
              "locations",
              get(atoms.mid),
              get(atoms.accessToken),
            ]);
          const currentLocationId = get(atoms.currentLocationId);
          const currentLocation = locations?.locations?.find(
            (i) => i._id === currentLocationId,
          );
          // TODO: get locations here and find by id.. for merchant with queryClient
          // idk if this is best place for it but suspense making it real hard to correlate error between queries/mutations
          return resyncMutation(currentLocation, get).then(() => {
            return queryClient
              .refetchQueries({
                queryKey: ["menu"],
                type: "active",
              })
              .then(() => {
                return fetchRequest(
                  `/catalog/details?location_id=${locationId}`,
                );
              });
          });
        } else {
          throw error;
        }
      }
    },
  })),
  /** This is used for very specific derived queries from head that invalidate seperate from catalogDetails */
  catalogHead: wrapper<
    components["schemas"]["GetLocationCatalogDetailsResponseDto"]["head"]
  >((get) => ({
    refetchOnWindowFocus: true,
    queryKey: ["catalog", "head", get(atoms.currentLocationId)],
    queryFn: async ({ queryKey: [, , location_id] }) => {
      const details = await queryClient.fetchQuery<
        components["schemas"]["GetLocationCatalogDetailsResponseDto"]
      >({
        queryKey: ["catalog", "details", location_id],
        /** This is for weirdness to make sure we dont fetch a stale head */
        staleTime: 0,
      });

      /** Cant get this to work cause it doesnt 'depend' on the first query so nothing reliabily setting this queryData */
      // const details = await queryClient.getQueryData<
      //   components["schemas"]["GetLocationCatalogDetailsResponseDto"]
      // >(["catalog", "details", location_id]);

      return details?.head || null;
    },
  })),
  archived: wrapper<components["schemas"]["GetArchivedObjectsResponseDto"]>(
    (get) => ({
      queryKey: ["archived", get(atoms.currentCatalogId)],
      queryFn: ({ queryKey: [, id] }) => {
        if (!id) return {};
        return fetchRequest(
          `/catalog/archived?catalog_id=${get(atoms.currentCatalogId)}`,
        );
      },
    }),
  ),
  latestInvoice: wrapper<{ invoice: any | null }>((get) => ({
    queryKey: ["queryLatestInvoice", get(atoms.accessToken)],
    queryFn: async ({ queryKey: [, token] }) => {
      if (!token || !cookies.get(cookies.options.org_id)) return [];
      return postRequest("/invoice/get/most-recent", {
        arg: {
          org_id: cookies.get(cookies.options.org_id),
        },
      });
    },
  })),
  subscription: wrapper<components["schemas"]["GetSubscriptionResponseDto"]>(
    (get) => ({
      queryKey: ["querySubscription", get(atoms.accessToken), get(atoms.orgId)],
      queryFn: async ({ queryKey: [, token, orgId] }) => {
        if (!token || !orgId) return null;
        return postRequest("/subscription/get", {
          arg: {
            org_id: orgId,
          },
        });
      },
    }),
  ),
  menuSchedules: wrapper<
    components["schemas"]["GetSchedulesForLocationResponseDto"]
  >((get) => ({
    queryKey: ["menuSchedules", get(atoms.currentLocationId)],
    queryFn: ({ queryKey: [, id] }) => {
      if (!id) return null;
      return fetchRequest(`/menu/schedules?location_id=${id}`);
    },
  })),
  members: wrapper<components["schemas"]["GetOrgMembersResponseDto"]>(
    (get) => ({
      queryKey: ["members", get(atoms.orgId)],
      queryFn: ({ queryKey: [_, org_id] }) => {
        if (!org_id) return null;
        return fetchRequest(`/org/members?org_id=${org_id}`);
      },
    }),
  ),
  menuMatch: wrapper<components["schemas"]["RunSmartMenuResponseDto"]>(
    (get) => ({
      queryKey: ["menuMatch", get(atoms.currentLocationId)],
      queryFn: async () => {
        const id = get(atoms.currentLocationId);
        if (!id) return null;
        return fetchRequest(`/menu-match?location_id=${id}&source=uber`);
      },
    }),
  ),
  menuMatchRemaining: wrapper<
    components["schemas"]["GetResultCountsResponseDto"]
  >((get) => ({
    queryKey: ["menuMatchRemaining", get(atoms.currentLocationId)],
    queryFn: ({ queryKey: [, locationId] }) => {
      if (!locationId) return null;
      return fetchRequest(
        `/menu-match/counts?location_id=${locationId}&source=uber`,
      );
    },
  })),
  ordersActive: wrapper<components["schemas"]["GetActiveOrdersResponseDto"]>(
    (get) => ({
      queryKey: ["activeOrders", get(atoms.currentLocationId)],
      /**
       *
       * @todo should be a fetchRequest with query param / GET request.
       * Fix backend at the same time (POST -> GET)
       */
      queryFn: ({ queryKey: [, locationId] }) => {
        if (!locationId) return { orders: [] };
        return postRequest(`/orders/active`, {
          arg: { location_id: locationId, limit: 50 },
        });
      },
      refetchOnWindowFocus: true,
      refetchInterval: 60000,
      staleTime: 60000,
    }),
  ),
  ordersScheduled: wrapper<components["schemas"]["GetActiveOrdersResponseDto"]>(
    (get) => ({
      queryKey: ["scheduledOrders", get(atoms.currentLocationId)],
      /**
       *
       * @todo should be a fetchRequest with query param / GET request.
       * Fix backend at the same time (POST -> GET)
       */
      queryFn: ({ queryKey: [, locationId] }) => {
        if (!locationId) return { orders: [] };
        return postRequest(`/orders/scheduled`, {
          arg: { location_id: locationId, limit: 50 },
        });
      },
      refetchOnWindowFocus: true,
      refetchInterval: 60000,
      staleTime: 60000,
    }),
  ),

  ordersFailActive: wrapper<components["schemas"]["GetOrdersDto"], IFixMe>(
    (get) => ({
      queryKey: ["failOrders", "active", get(atoms.currentLocationId)],
      /**
       *
       * @todo should be a fetchRequest with query param / GET request.
       * Fix backend at the same time (POST -> GET)
       */
      queryFn: ({ queryKey: [, , locationId] }) => {
        if (!locationId) return { orders: [] };
        const startOfDay = new Date();
        startOfDay.setHours(0, 0, 0, 0);
        return postRequest(`/orders/failed`, {
          arg: {
            location_id: locationId,
            limit: 50,
            after_change_time: startOfDay.getTime(),
          },
        });
      },
      refetchOnWindowFocus: true,
      refetchInterval: 60000,
      staleTime: 60000,
    }),
  ),

  ordersComplete: wrapper<components["schemas"]["GetOrdersDto"], IFixMe>(
    (get) => ({
      queryKey: ["completeOrders", get(atoms.currentLocationId)],
      /**
       *
       * @todo should be a fetchRequest with query param / GET request.
       * Fix backend at the same time (POST -> GET)
       */
      queryFn: ({ queryKey: [, locationId] }) => {
        if (!locationId) return { orders: [] };
        return postRequest(`/orders/completed`, {
          arg: { location_id: locationId, limit: 50 },
        });
      },
    }),
  ),

  ordersFail: wrapper<components["schemas"]["GetOrdersDto"], IFixMe>((get) => ({
    queryKey: ["failOrders", get(atoms.currentLocationId)],
    /**
     *
     * @todo should be a fetchRequest with query param / GET request.
     * Fix backend at the same time (POST -> GET)
     */
    queryFn: ({ queryKey: [, locationId] }) => {
      if (!locationId) return { orders: [] };
      const ONE_WEEK = 60_000 * 60 * 24 * 7;
      const startOfDay = new Date();
      startOfDay.setHours(0, 0, 0, 0);
      return postRequest(`/orders/failed`, {
        arg: {
          location_id: locationId,
          limit: 50,
          after_change_time: Date.now() - ONE_WEEK,
          before_change_time: startOfDay.getTime(),
        },
      });
    },
  })),
  externalPosLocations: wrapper<components["schemas"]["GetLocationsResult"]>(
    (get) => ({
      queryKey: ["externalPosLocations", get(atoms.pendingPosIntegration)?._id],
      queryFn: ({ queryKey: [, id] }) => {
        return id
          ? fetchRequest(
              `/location/source?_id=${get(atoms.pendingPosIntegration)?._id}`,
            )
          : {
              schedules: {},
              locations: [],
            };
      },
    }),
  ),
};
