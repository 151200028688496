import { useCatalogQuery } from "#/hooks/queries/use-catalog-query";
import { atoms } from "#/lib/atoms/atoms";
import { useSetAtom } from "jotai";
import { useEffect } from "react";

export default function useCatalog() {
  const { data } = useCatalogQuery();
  const setCatalog = useSetAtom(atoms.currentCatalogId);

  useEffect(() => {
    if (data?.catalog_id) {
      setCatalog(data.catalog_id);
    }
  }, [data?.catalog_id, setCatalog]);

  return {
    data: data?.catalog || {
      item_family: [],
      category: [],
      modifier: [],
      modifier_group: [],
      item: [],
      tax: [],
    },
    last_updated_at: data?.last_updated_at,
  };
}
