import { Images } from "#/themes";
import { Theme } from "#/types.ts/other";

export const StreamTheme: Theme = {
  borderColor: "#DDE0E9",
  accentColor: "#DDE0E9",
  primaryColor: "#27C499",
  secondaryColor: "#0B1130",
  mutedColor: "#80849C",
  bgColor: "#F4F7FC",
  foregroundColor: "#0B1130",
  cardForegroundColor: "#0B1130",
  // non editable
  primaryForegroundColor: "#ffff",
  primaryMutedColor: "rgba(255, 255, 255, 0.64)",
  secondaryForegroundColor: "#ffff",
  cardColor: "white",
  destructiveColor: "#C4274D",
  destructiveForegroundColor: "#fff",
  secondaryAccentColor: "rgba(255, 255, 255, 0.1)",
  secondaryMutedColor: "rgba(255, 255, 255, 0.64)",
  secondaryBorderColor: "rgba(255, 255, 255, 0.24)",
  imageUrl: Images.logo,
  iconUrl: Images.icon,
  name: "Stream",
  flags: {
    intercomEnabled: true,
    aiEnabled: true,
    customRolesEnabled: true,
  },
};
