"use client";

import { twMerge } from "tailwind-merge";

type HighlightImageProps = {
  image: React.ReactElement;
  rootStyles?: string;
  bgStyles?: string;
};

export const HighlightImage = ({
  image,
  rootStyles,
  bgStyles,
}: HighlightImageProps) => {
  return (
    <div
      className={twMerge(
        "h-[48px] w-[48px] relative overflow-hidden rounded-full flex items-center justify-center",
        rootStyles,
      )}
    >
      <div
        className={twMerge(
          `opacity-[0.15] absolute z-10 h-full w-full`,
          `bg-primary`,
          bgStyles,
        )}
      />
      {image}
    </div>
  );
};
